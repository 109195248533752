<template>
  <div>
    <div class="mb-5">
      <b-modal id="add-update-rack-map-modal" title="Rak Add/Update" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">

          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="row_number" class="col-form-label col-form-label-sm">Row number <span class="custom-required">*</span></label>
              <input type="text" v-model="rack_map.row_number" v-validate="'required|integer'" id="row_number" name="row_number" class="form-control form-control-sm" placeholder="Enter row number" v-uppercase/>
              <div class="invalid-feedback">Row number is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="rack_number" class="col-form-label col-form-label-sm">Rak number <span class="custom-required">*</span></label>
              <input type="text" v-model="rack_map.rack_number" v-validate="'required|integer'" id="rack_number" name="rack_number" class="form-control form-control-sm" placeholder="Enter rack number" v-uppercase/>
              <div class="invalid-feedback">Rak number is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="level_number" class="col-form-label col-form-label-sm">Level number <span class="custom-required">*</span></label>
              <input type="text" v-model="rack_map.level_number" v-validate="'required|integer'" id="level_number" name="level_number" class="form-control form-control-sm" placeholder="Enter level number" v-uppercase/>
              <div class="invalid-feedback">Level number is required</div>
            </div>
          </div>

          <div class="form-row mt-2">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <p>Code: <strong>{{ code }}</strong></p>
            </div>
          </div>

          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" v-if="!this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="save">Add</a-button>
              <a-button type="primary" v-if="this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('add-update-rack-map-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'addUpdateRakMap',
  props: ['rack_map', 'btnEdit'],
  data() {
    return {
      validation_errors: {},
      show: false,
      loader: false,
    }
  },
  mounted() { },
  computed: {
    code() {
      const rowNumber = this.rack_map.row_number
      const rackNumber = this.rack_map.rack_number
      const levelNumber = this.rack_map.level_number
      const smallCode = ('Row ' + rowNumber + ' Rack ' + rackNumber + ' Level ' + levelNumber)
      if (rowNumber && rackNumber && levelNumber) {
        return smallCode.toString().toUpperCase()
      }
      return ''
    },
  },
  methods: {
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/rack-maps', this.rack_map).then(response => {
            this.loader = false
            if (response.data.error) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#rack_map_list').DataTable().destroy()
              this.$emit('resetForm')
              this.$emit('getRackMaps')
            }
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.put('api/rack-maps/' + this.rack_map.id, this.rack_map)
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                $('#rack_map_list').DataTable().destroy()
                this.$bvModal.hide('add-update-rack-map-modal')
                this.$emit('getRackMaps')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.loader = false
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
